import LoctiteImg from '../assets/images/focus_cameras/loctite.png'
import { Stack, Typography } from '@mui/material'

export const PreperationStep = () => (
    <>
        <Typography variant='h5'>Preparation</Typography>
        <Stack direction={'row'} spacing={1} justifyContent={'space-between'}>
            <Stack spacing={1}>
                <Typography component={'span'} variant='body2'>
                    1. Camera lenses must be clean!
                </Typography>
                <Typography component={'span'} variant='body2'>
                    2. Place machine on a flat floor
                </Typography>
                <Typography component={'span'} variant='body2'>
                    3. Place a 14cm high euro pallet between the tracks of the machine
                </Typography>
                <Typography component={'span'} variant='body2'>
                    4. Place the calibration or focus board on the euro pallet. board surface should be 15cm above the
                    ground
                </Typography>
            </Stack>
            <img src={LoctiteImg} style={{ borderRadius: '4px' }} alt='description' />
        </Stack>
    </>
)
