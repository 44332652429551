import React, { FC, Suspense, lazy } from 'react'
import { Route, Routes, Outlet, Navigate } from 'react-router-dom'
import { Alert, ErrorWrapper, UIAppBar, PdfView } from './_components'
import { useKeyBindings, NetworkStatus } from './_helpers'
import { List as MachinesList } from './machines/List'
import { List as FieldsList } from './fields/List'
import { List as UsersList } from './users/List'
import { List as CISList, Slideshow } from './cis'
import { List as MessagesList, Messages } from './messages'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { Controller as FieldController } from './crops/Controller'
import { View as MachineView, RouteListener } from './machines/View'
import { Add as AddField } from './fields/Add'
import { Add as UserAdd } from './users/Add'
import { Box } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { lightTheme } from './themes'
import { styles } from './styles'
import { impersonateUsersAtom } from './_state'
import { useRecoilValue } from 'recoil'
import UserService from './services/UserService'
import NotAllowed from './_components/NotAllowed'
import { TulipBedsView, CropEditorView, BedCollectionsView } from './crops'

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: true,
        },
    },
})

const Survey = lazy(() => import('./survey'))
const SupportPanel = lazy(() => import('./support'))
const Sms = lazy(() => import('./sms'))
const Service = lazy(() => import('./service'))
const PlantMarking = lazy(() => import('./plant_marking'))
const SerialNumber = lazy(() => import('./serial_number'))

export { App }

const Layout: FC = () => (
    <>
        <UIAppBar />
        <Outlet />
    </>
)

function App() {
    useRecoilValue(impersonateUsersAtom)
    if (!UserService.isLoggedIn()) UserService.doLogin()
    useKeyBindings()
    return (
        <>
            <ErrorWrapper>
                <QueryClientProvider client={queryClient}>
                    <NetworkStatus />
                    <Box sx={[styles.App.MainBox]}>
                        <Alert />
                        <ThemeProvider theme={lightTheme}>
                            <Routes>
                                <Route element={<Layout />}>
                                    <Route path='/machines' element={<MachinesList />} />
                                    <Route path='/users'>
                                        <Route
                                            index
                                            element={
                                                UserService.hasRole(['admin', 'manager']) ? (
                                                    <UsersList />
                                                ) : (
                                                    <NotAllowed />
                                                )
                                            }
                                        />
                                        <Route path='add' element={<UserAdd />} />
                                        <Route
                                            path='sms'
                                            element={
                                                <Suspense fallback={<div>Loading...</div>}>
                                                    <Sms />
                                                </Suspense>
                                            }
                                        />
                                    </Route>
                                    <Route path='/fields'>
                                        <Route index element={<FieldsList />} />
                                        <Route path='add' element={<AddField />} />
                                        <Route path=':field_id/crops/:crop_id/*' element={<FieldController />}>
                                            <Route path='view/*' element={<TulipBedsView />} />
                                            <Route path='beds/*' element={<BedCollectionsView />} />
                                        </Route>
                                    </Route>
                                    <Route path='/messages/*' element={<Messages />} />
                                    <Route
                                        path='/manual'
                                        element={
                                            <PdfView
                                                pdf={`${process.env.REACT_APP_API_URL}/api/v1/utils/manual_v04.pdf`}
                                            />
                                        }
                                    />
                                    <Route path={'/messages/23'} element={<MessagesList />} />
                                    <Route
                                        path='/service/*'
                                        element={
                                            UserService.hasRole(['admin']) ? (
                                                <Suspense fallback={<div>Loading...</div>}>
                                                    <Service />
                                                </Suspense>
                                            ) : (
                                                <NotAllowed />
                                            )
                                        }
                                    />
                                    <Route
                                        path='/production/*'
                                        element={
                                            UserService.hasRole(['admin', 'manufacturer']) ? (
                                                <Suspense fallback={<div>Loading...</div>}>
                                                    <SerialNumber />
                                                </Suspense>
                                            ) : (
                                                <NotAllowed />
                                            )
                                        }
                                    />
                                    <Route
                                        path='/survey'
                                        element={
                                            <Suspense fallback={<div>Loading...</div>}>
                                                <Survey />
                                            </Suspense>
                                        }
                                    />
                                </Route>

                                {/* Does not render UIAppBar from here */}
                                <Route element={<Outlet />}>
                                    <Route path='/fields/:field_id/crops/:crop_id/*' element={<FieldController />}>
                                        <Route path='edit/*' element={<CropEditorView />} />
                                    </Route>
                                </Route>
                                <Route
                                    path={'/machines/:machine_name/support'}
                                    element={
                                        UserService.hasRole(['admin']) ? (
                                            <Suspense fallback={<div>Loading...</div>}>
                                                <SupportPanel />
                                            </Suspense>
                                        ) : (
                                            <NotAllowed />
                                        )
                                    }
                                />
                                <Route
                                    path={'/machines/:machine_name/*'}
                                    element={
                                        <>
                                            <MachineView />
                                            <RouteListener />
                                        </>
                                    }
                                ></Route>
                                <Route
                                    path='/plant-marking/*'
                                    element={
                                        <Suspense fallback={<div>Loading...</div>}>
                                            <PlantMarking />
                                        </Suspense>
                                    }
                                />
                                <Route
                                    path='/cameras'
                                    element={UserService.hasRole(['admin']) ? <CISList /> : <NotAllowed />}
                                />
                                <Route
                                    path='/Slideshow'
                                    element={UserService.hasRole(['admin']) ? <Slideshow /> : <NotAllowed />}
                                />
                                <Route path='/' element={<Navigate to='/machines' />} />
                            </Routes>
                        </ThemeProvider>
                    </Box>
                </QueryClientProvider>
            </ErrorWrapper>
        </>
    )
}
