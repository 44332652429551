import { atom, selector } from 'recoil'
import { H2LFieldJson, BedGeoJSON, CultivarGroup } from '../types'
import { selectedBedIDsAtom } from './selectedBedIDs'

const h2lFieldJsonAtom = atom<H2LFieldJson | null>({
    key: 'h2lFieldJson',
    default: null,
})

// Selector to retrieve the list of cultivar groups, each containing its associated beds
const cultivarListSelector = selector<Array<CultivarGroup> | null>({
    key: 'cultivar_list',
    get: ({ get }) => {
        // Retrieve beds from the atom state
        const beds = get(h2lFieldJsonAtom)?.beds;
        if (!beds) return null

        // Initialize a Map to group beds by group_id
        const groups = new Map<number, CultivarGroup>()

        // Iterate over each bed to build the group structure
        beds.forEach((bed: BedGeoJSON) => {
            const { group_id, name, cultivar, size, strokeColor } = bed.properties

            // Check if the group_id already exists in the Map
            if (!groups.has(group_id)) {
                // If not, create a new CultivarGroup with an empty beds array
                groups.set(group_id, {
                    group_id,
                    name,
                    cultivar,
                    size,
                    strokeColor,
                    beds: [],
                });
            }

            // Add the current bed to the beds array of the respective group
            groups.get(group_id)!.beds.push(bed)
        });

        // Convert the Map values to an array of CultivarGroup objects and return
        return [...groups.values()]
    },
})

const groupIDsToBedIDsSelector = selector({
    key: 'groupIDsToBedIDs',
    get: ({ get }): Array<number> | [] => {
        const h2lFieldJson = get(h2lFieldJsonAtom)
        const selectedBedIDs = get(selectedBedIDsAtom)
        const bedIDs: number[] = []
        if (selectedBedIDs && h2lFieldJson) {
            h2lFieldJson.beds.forEach((bed) => {
                if (selectedBedIDs.has(bed.id) && !bed.properties.skip) {
                    bedIDs.push(bed.id)
                }
            })
        }
        return bedIDs
    },
})

export { h2lFieldJsonAtom, cultivarListSelector, groupIDsToBedIDsSelector }
