import React, { useState, useEffect, CSSProperties, useCallback } from 'react'
import { useImageActions } from '../_actions'
import { Stack, CircularProgress, Box, IconButton, useMediaQuery } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import RemoveIcon from '@mui/icons-material/Remove'
import AddIcon from '@mui/icons-material/Add'
import ZoomOutIcon from '@mui/icons-material/ZoomOut'
import NoPhotographyIcon from '@mui/icons-material/NoPhotography'
import CloseIcon from '@mui/icons-material/Close'
import CropFreeIcon from '@mui/icons-material/CropFree'

const ZoomControl = ({
    handleZoomIn,
    handleZoomOut,
    handleResetZoom,
    isZoomed,
}: {
    handleZoomIn: () => void
    handleZoomOut: () => void
    handleResetZoom: () => void
    isZoomed: boolean
}) => (
    <Box
        display='flex'
        alignItems='center'
        justifyContent='space-between'
        sx={{
            position: 'absolute',
            bottom: '20px',
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 3001,

            width: '120px',
            backgroundColor: '#222',
            borderRadius: '24px',
            padding: '4px 8px',
        }}
    >
        <IconButton
            size='small'
            color='secondary'
            onClick={(e) => {
                e.stopPropagation()
                handleZoomOut()
            }}
        >
            <RemoveIcon />
        </IconButton>
        <IconButton
            size='small'
            color={isZoomed ? 'secondary' : 'primary'}
            onClick={(e) => {
                e.stopPropagation()
                handleResetZoom()
            }}
            disabled={!isZoomed}
        >
            <ZoomOutIcon />
        </IconButton>
        <IconButton
            size='small'
            color='secondary'
            onClick={(e) => {
                e.stopPropagation()
                handleZoomIn()
            }}
        >
            <AddIcon />
        </IconButton>
    </Box>
)

const ImageThumbnail = ({
    imageData,
    alt,
    containerStyle,
    onImageClick,
}: {
    imageData: string
    alt: string
    containerStyle: CSSProperties
    onImageClick: () => void
}) => (
    <Box sx={{ ...containerStyle }}>
        <img
            src={imageData}
            alt={alt}
            style={{
                ...containerStyle,
                objectFit: 'cover',
                cursor: 'pointer',
                transition: 'transform 0.2s ease-in-out',
            }}
            onClick={onImageClick}
        />
    </Box>
)

const FullscreenView = ({ imageData, alt, onClose }: { imageData: string; alt: string; onClose: () => void }) => {
    const isMobile = useMediaQuery('(max-width:600px)')
    const initialTranslateX = isMobile ? 2 : 20
    const theme = useTheme()

    // Zooming
    const [zoomLevel, setZoomLevel] = useState(1)
    const isZoomed = zoomLevel !== 1
    const handleZoomIn = useCallback(() => setZoomLevel((prevZoom) => Math.min(prevZoom + 0.1, 3)), [])
    const handleZoomOut = useCallback(() => setZoomLevel((prevZoom) => Math.max(prevZoom - 0.1, 1)), [])
    const handleResetZoom = useCallback(() => setZoomLevel(1), [])
    const handleWheelZoom = useCallback((e: React.WheelEvent<HTMLImageElement>) => {
        if (e.deltaY < 0) {
            setZoomLevel((prevZoom) => Math.min(prevZoom + 0.1, 3))
        } else {
            setZoomLevel((prevZoom) => Math.max(prevZoom - 0.1, 1))
        }
    }, [])

    // Panning
    const [isDragging, setIsDragging] = useState(false)
    const [startX, setStartX] = useState(0)
    const [startY, setStartY] = useState(0)
    const [translateX, setTranslateX] = useState(initialTranslateX)
    const [translateY, setTranslateY] = useState(0)

    // Desktop
    const handleMouseDown = (e: React.MouseEvent) => {
        e.preventDefault() // Prevent default browser behavior, must click two times to move without it
        setIsDragging(true)
        setStartX(e.clientX - translateX)
        setStartY(e.clientY - translateY)
    }
    const handleMouseMove = (e: React.MouseEvent) => {
        if (!isDragging) return
        setTranslateX(e.clientX - startX)
        setTranslateY(e.clientY - startY)
    }
    const handleResetDragging = useCallback(() => {
        setTranslateX(initialTranslateX)
        setTranslateY(0)
    }, [])
    const handleMouseUp = () => setIsDragging(false)
    const handleMouseLeave = () => setIsDragging(false)

    return (
        <Box
            sx={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.9)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 2000,
            }}
        >
            {/* Overlay to detect clicks outside the image */}
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                }}
                onClick={onClose}
            />
            {/* Top right buttons */}
            <Box
                sx={{
                    position: 'absolute',
                    top: '20px',
                    right: '20px',
                    zIndex: 3001,
                }}
            >
                <Stack spacing={2}>
                    <IconButton
                        onClick={onClose}
                        color='secondary'
                        sx={{
                            backgroundColor: theme.palette.primary.main,
                            '&:hover': {
                                backgroundColor: theme.palette.primary.light,
                            },
                        }}
                    >
                        <CloseIcon />
                    </IconButton>

                    {(translateX !== initialTranslateX || translateY !== 0) && (
                        <IconButton
                            onClick={handleResetDragging}
                            sx={{
                                backgroundColor: theme.palette.primary.main,
                                '&:hover': {
                                    backgroundColor: theme.palette.primary.light,
                                },
                            }}
                        >
                            <CropFreeIcon />
                        </IconButton>
                    )}
                </Stack>
            </Box>

            {/* Image */}
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                    overflow: 'hidden',
                    cursor: isDragging ? 'grabbing' : 'grab',
                }}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseLeave}
            >
                <img
                    src={imageData}
                    alt={alt}
                    style={{
                        maxWidth: '90%',
                        maxHeight: '90%',
                        objectFit: 'contain',
                        transform: `translate(${translateX}px, ${translateY}px) scale(${zoomLevel})`,
                        transition: isDragging ? 'none' : 'transform 0.2s ease-in-out',
                        zIndex: 2000,
                        cursor: isDragging ? 'grabbing' : 'grab',
                    }}
                    onWheel={handleWheelZoom}
                    onClick={(e) => e.stopPropagation()} // Prevent closing when clicking on the image
                />
            </div>
            <ZoomControl
                handleZoomIn={handleZoomIn}
                handleZoomOut={handleZoomOut}
                handleResetZoom={handleResetZoom}
                isZoomed={isZoomed}
            />
        </Box>
    )
}

const ImageLoader = ({
    filepath,
    loading,
    containerStyle = {},
    alt = 'Loaded image',
    enableFullscreen = false,
}: {
    filepath?: string
    containerStyle?: CSSProperties
    alt?: string
    enableFullscreen?: boolean
    loading?: boolean
}) => {
    const [imageData, setImageData] = useState(null)
    const [fetching, setFetching] = useState(false)
    const [error, setError] = useState(null)
    const [isFullscreen, setIsFullscreen] = useState(false)
    const imageActions = useImageActions()

    const getImage = (filepath: string) => {
        setFetching(true)
        return imageActions
            .getCalibrationImage(filepath)
            .then((res) => setImageData(URL.createObjectURL(res)))
            .catch(() => setError('Could not load image'))
            .finally(() => setFetching(false))
    }

    const handleImageClick = () => enableFullscreen && setIsFullscreen(true)
    const handleCloseFullscreen = useCallback(() => setIsFullscreen(false), [])

    useEffect(() => {
        if (filepath && !loading) getImage(filepath)
    }, [filepath])

    if (loading || fetching) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', ...containerStyle }}>
                <CircularProgress color={'secondary'} />
            </Box>
        )
    }

    if (!filepath) {
        return (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', ...containerStyle }}>
                <NoPhotographyIcon fontSize='large' />
            </Box>
        )
    }
    if (error) return <Box style={{ color: 'red', ...containerStyle }}>Error: {error}</Box>

    if (imageData)
        return (
            <>
                <ImageThumbnail
                    imageData={imageData}
                    alt={alt}
                    containerStyle={containerStyle}
                    onImageClick={handleImageClick}
                />
                {isFullscreen && <FullscreenView imageData={imageData} alt={alt} onClose={handleCloseFullscreen} />}
            </>
        )
}

export default ImageLoader
