import { useState, MouseEvent } from 'react'
import { Box, Typography, Button, Popover } from '@mui/material'

export const CropParseStatusIcon = ({ fieldTestPassed }: { fieldTestPassed: boolean }) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined

    const handlePopoverClick = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        setAnchorEl(e.currentTarget)
    }

    const handlePopoverClose = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        setAnchorEl(null)
    }
    return (
        <>
            <Button
                aria-describedby={id}
                sx={{
                    whiteSpace: 'nowrap',
                    minWidth: 'max-content',
                }}
                variant='text'
                onClick={handlePopoverClick}
            >
                {fieldTestPassed ? '✅ ' : '❌ '}
            </Button>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {fieldTestPassed ? (
                    <Box sx={{ p: 2 }}>
                        <Typography>🇬🇧 Tasks can be made that cover the whole field</Typography>
                        <Typography>🇳🇱 Taken kunnen worden gemaakt die het hele veld dekken</Typography>
                    </Box>
                ) : (
                    <Box sx={{ p: 2 }}>
                        <Typography component='div' sx={{ whiteSpace: 'pre-line' }}>
                            🇬🇧 Field requires manual corrections before it is ready for creating tasks.{' '}
                            <Box fontWeight={500}>We are working on it!</Box>
                        </Typography>
                        <Typography component='div' sx={{ whiteSpace: 'pre-line' }}>
                            🇳🇱 Veld vereist handmatige correcties voordat taken kunnen worden gemaakt.{' '}
                            <Box fontWeight={500}>We zijn ermee bezig!</Box>
                        </Typography>
                    </Box>
                )}
            </Popover>
        </>
    )
}
