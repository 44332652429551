import { useSetRecoilState, useRecoilValue, useResetRecoilState } from 'recoil'
import { selectedBedIDsAtom } from '../_state'

export { useBedIDsSelector }
function useBedIDsSelector() {
    const selectedBedIDs = useRecoilValue(selectedBedIDsAtom)
    const setSelectedBedIDs = useSetRecoilState(selectedBedIDsAtom)
    const resetSelectedBedIDs = useResetRecoilState(selectedBedIDsAtom)

    return {
        toggleSelected,
        addMany,
        replaceMany,
        removeMany,
        resetSelectedBedIDs,
    }

    function toggleSelected(bedID: number) {
        const newSet = new Set(selectedBedIDs)
        if (newSet.has(bedID)) {
            newSet.delete(bedID)
        } else {
            newSet.add(bedID)
        }
        setSelectedBedIDs(newSet)
    }

    function addMany(bedIDs: number[]) {
        const newSet = new Set(selectedBedIDs)
        bedIDs.forEach(bedID => newSet.add(bedID))
        setSelectedBedIDs(newSet)
    }

    function replaceMany(bedIDs: number[]) {
        const newSet = new Set([])
        bedIDs.forEach(bedID => newSet.add(bedID))
        setSelectedBedIDs(newSet)
    }

    function removeMany(bedIDs: number[]) {
        const newSet = new Set(selectedBedIDs)
        bedIDs.forEach(bedID => newSet.delete(bedID))
        setSelectedBedIDs(newSet)
    }
}