import S180Camera1 from '../assets/images/focus_cameras/camera1.png'
import S180Camera2 from '../assets/images/focus_cameras/camera2.png'
import S180Camera3 from '../assets/images/focus_cameras/camera3.png'
import S225Camera1 from '../assets/images/focus_cameras/s225_camera1.png'
import S225Camera2 from '../assets/images/focus_cameras/s225_camera2.png'
import S225Camera3 from '../assets/images/focus_cameras/s225_camera3.png'
import S225Camera4 from '../assets/images/focus_cameras/s225_camera4.png'
import { Typography } from '@mui/material'

export const LocateCameraStep = ({ model, cameraNum }: { model: string; cameraNum: string }) => {
    let imgSrc
    if (model === 'S180') {
        switch (cameraNum) {
            case 'camera1':
                imgSrc = S180Camera1
                break
            case 'camera2':
                imgSrc = S180Camera2
                break
            case 'camera3':
                imgSrc = S180Camera3
                break
            default:
                imgSrc = S180Camera1
        }
    } else {
        switch (cameraNum) {
            case 'camera1':
                imgSrc = S225Camera1
                break
            case 'camera2':
                imgSrc = S225Camera2
                break
            case 'camera3':
                imgSrc = S225Camera3
                break
            case 'camera4':
                imgSrc = S225Camera4
                break
            default:
                imgSrc = S225Camera1
        }
    }

    return (
        <>
            <Typography variant='h5'>
                Locate: {cameraNum.charAt(0).toUpperCase() + cameraNum.slice(1).toLowerCase()}
            </Typography>
            <img src={imgSrc} alt='description' style={{ borderRadius: '4px', width: '100%', height: 'auto' }} />
        </>
    )
}
