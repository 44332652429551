import { forwardRef, useState, useImperativeHandle, useRef, ChangeEvent, useEffect } from 'react'
import { io } from 'socket.io-client'
import UserService from '../services/UserService'
import { ModalBox, SelectBox } from '.'
import { Box, Button, Typography } from '@mui/material'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { SystemCommandRequest, TerminalWindowRef } from '../types'
import { styles } from '../styles'
import { useRecoilValue } from 'recoil'
import { machineNameAtom } from '../_state'
import { useNavigate } from 'react-router-dom'

export const TerminalWindow = forwardRef<TerminalWindowRef>((props, ref) => {
    const [commandOutput, setCommandOutput] = useState('')
    const commandOutputEndRef = useRef<HTMLSpanElement | null>(null)
    const [textColor, setTextColor] = useState('#ffffff')

    const scrollToBottom = () => commandOutputEndRef.current?.scrollIntoView({ behavior: 'smooth' })

    useImperativeHandle(ref, () => ({
        // ERROR OUTPUT
        // setOutput: (msg, isError = false) => {
        //   const styledMsg = isError ? `<span style="color: red;">${msg}</span>` : msg
        //   setCommandOutput((commandOutput) => commandOutput + styledMsg)
        // },
        setOutput: (msg: string) => setCommandOutput((commandOutput) => commandOutput + msg),
    }))
    if (commandOutput.search('FAILED') > -1 && textColor != '#ff0000') setTextColor('#ff0000')

    useEffect(() => {
        scrollToBottom()
    }, [])

    useEffect(() => {
        scrollToBottom()
    }, [commandOutput])

    return (
        <>
            <Box
                sx={{
                    ...styles.TerminalWindow.Box,
                    overflowY: 'auto',
                }}
            >
                {/* ERROR OUTPUT */}
                {/* <Typography
          sx={{ ...styles.TerminalWindow.Typography }}
          dangerouslySetInnerHTML={{ __html: commandOutput }}
          style={{ color: textColor }}
          ref={commandOutputEndRef}
        /> */}
                <Typography sx={{ ...styles.TerminalWindow.Typography, ...{ color: textColor } }}>
                    {commandOutput}
                    <span ref={commandOutputEndRef} />
                </Typography>
            </Box>
        </>
    )
})
TerminalWindow.displayName = 'TerminalWindow'

export function SystemCommandsManager({ preSelectedCmd }: { preSelectedCmd?: string }) {
    const machineName = useRecoilValue(machineNameAtom)
    if (!machineName) return <>Machine name is not available.</>

    const socket = io(`${process.env.REACT_APP_FSS_URL}/${machineName}/cmd`, {
        auth: {
            token: UserService.getToken(),
            client_type: 'ui-client',
        },
    })

    const [commandList, setCommandList] = useState<any[]>([])
    const [selectedCommand, setSelectedCommand] = useState(preSelectedCmd ? preSelectedCmd : 'beep')
    const [commandRunning, setCommandRunning] = useState(false)
    const terminalWindowRef = useRef<TerminalWindowRef | null>(null)

    const handleSendCommand = (cmd: SystemCommandRequest) => {
        console.log(`Sending command: ${cmd['name']} args: ${cmd['args']}`)
        terminalWindowRef.current?.setOutput(`[${cmd['name']}]: Running...\n`)
        socket.emit('system_command', cmd)
    }
    const handleChangeSelectedCommand = (event: ChangeEvent<HTMLSelectElement>) =>
        setSelectedCommand(event.target.value)

    useEffect(() => {
        socket.on('CONNECTED', (c) => setCommandList(c['commands']))
        socket.on('UNAUTHORIZED', () => UserService.doLogout())

        socket.on('SC_STRM', (msg) => {
            if (msg['count'] === 0) {
                setCommandRunning(true)
            }
            terminalWindowRef.current?.setOutput(msg['data'])
            if (msg['count'] === -1) {
                terminalWindowRef.current?.setOutput(`[${msg['name']}]: Finished\n\n`)
                setCommandRunning(false)
            }
        })
        return () => {
            socket.off('CONNECTED')
            terminalWindowRef.current?.setOutput('')
            socket.off('SC_STRM')
            socket.close()
        }
    }, [])

    useEffect(() => {
        if (preSelectedCmd && commandList && commandList.includes(preSelectedCmd)) {
            handleSendCommand({
                name: preSelectedCmd,
                args: [machineName],
            })
        }
    }, [commandList])

    return (
        <>
            {commandList.length > 0 && (
                <>
                    {!preSelectedCmd && (
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                flexWrap: 'no-wrap',
                            }}
                        >
                            <SelectBox
                                inputLabel='Select a command'
                                value={selectedCommand}
                                options={commandList}
                                width={'100%'}
                                disabled={false}
                                handleChange={handleChangeSelectedCommand}
                            />
                            <Button
                                variant='contained'
                                sx={{ m: 1 }}
                                disabled={commandRunning || selectedCommand === null}
                                onClick={() => {
                                    if (selectedCommand) {
                                        handleSendCommand({
                                            name: selectedCommand,
                                            args: [machineName],
                                        })
                                    }
                                }}
                                startIcon={<KeyboardArrowRightIcon />}
                            >
                                Run
                            </Button>
                        </Box>
                    )}
                    <TerminalWindow ref={terminalWindowRef} />
                </>
            )}
        </>
    )
}

const SystemCommands = () => {
    const navigate = useNavigate()
    const handleClose = () => navigate(location.pathname.replace('/system-commands', ''))
    return (
        <ModalBox title={'System Commands'} open={true} handleDialogClose={handleClose}>
            <SystemCommandsManager />
        </ModalBox>
    )
}
export { SystemCommands }
