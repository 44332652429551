import { atom, selector } from 'recoil'
import {
    xavier1Atom,
    xavier2Atom,
    xavier3Atom,
    xavier4Atom,
    xavier7Atom,
    xavier8Atom,
    robotControlAtom,
    fluidInstrumentationAtom,
    treatmentAtom,
    robotZheightAtom,
    autoSafetyAtom,
    motorControlAtom,
    followPathAtom,
    subframePositionAtom,
    machineModelAtom,
    clusteringAtom,
    ussAtom,
} from '.'

enum States {
    'error',
    'loading',
    'idle',
    'running',
}

const miscellaneousAtom = atom<string | null>({
    key: 'miscellaneous_atom',
    default: null,
})

function getSelectorState(serviceStatuses: (number | undefined)[]): string {
    const min = Math.min(...serviceStatuses)

    return isNaN(min) ? States[0] : States[min]
}

const navigationState = selector<string>({
    key: 'navigation_state_selector',
    get: ({ get }) => {
        const gpsQuality = get(subframePositionAtom)?.qual
        if (gpsQuality !== 4) return States[1]
        return getSelectorState([
            get(ussAtom)?.status,
            get(autoSafetyAtom)?.status,
            get(followPathAtom)?.status,
            get(motorControlAtom)?.status,
            get(subframePositionAtom)?.status,
        ])
    },
})

const treatmentState = selector<string>({
    key: 'treatment_state_selector',
    get: ({ get }) => {
        return getSelectorState([
            get(clusteringAtom),
            get(fluidInstrumentationAtom)?.status,
            get(robotControlAtom)?.status,
            get(robotZheightAtom)?.status,
            get(treatmentAtom)?.status,
        ])
    },
})

const camerasState = selector<string>({
    key: 'cameras_state_selector',
    get: ({ get }) => {
        const atomsToCheck = [get(xavier1Atom), get(xavier2Atom), get(xavier3Atom), get(xavier7Atom)]

        if (get(machineModelAtom) === 'S225') {
            atomsToCheck.push(get(xavier4Atom), get(xavier8Atom))
        }

        return getSelectorState(atomsToCheck)
    },
})

export { navigationState, treatmentState, camerasState, miscellaneousAtom }
