import { useEffect } from 'react'
import { useSearchParams, useParams } from 'react-router-dom'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { FieldMenu } from '../_components'
import { h2lFieldJsonAtom, taskQuery } from '../_state'
import { Box, Drawer, Toolbar, Typography, Divider, Stack, Button } from '@mui/material'
import { TaskList } from '../tasks'
import { ReactComponent as TaskIcon } from '../assets/images/task.svg'
import SpaIcon from '@mui/icons-material/Spa'
import { TaskPlannerPanel } from '.'
import { useCropActions } from '../_actions'
import { CropParseStatusIcon } from './CropParseStatusIcon'

const TASK_PLANNER = 'task-planner'
const TASK_LIST = 'task-list'

export const CultivarsDrawer = () => {
    const [searchParams, setSearchParams] = useSearchParams()
    const cropID = parseInt(useParams<{ crop_id: string }>().crop_id)
    const drawerView = searchParams.get('drawerView')
    const h2lFieldJson = useRecoilValue(h2lFieldJsonAtom)
    const cropActions = useCropActions()

    const setTask = useSetRecoilState(taskQuery)
    const setH2lFieldJson = useSetRecoilState(h2lFieldJsonAtom)

    const handleToggleTaskList = () => {
        setTask({ data: null, isLoading: false })
        setSearchParams(
            {
                drawerView: drawerView === TASK_LIST ? TASK_PLANNER : TASK_LIST,
            },
            { replace: true },
        )
    }

    // Set a default query parameter when the component is first loaded and the parameter does not exist on the url
    useEffect(() => {
        if (!searchParams.has('drawerView')) {
            setSearchParams({ drawerView: TASK_PLANNER }, { replace: true })
        }
    }, [searchParams, setSearchParams])

    useEffect(() => {
        if (drawerView === TASK_PLANNER && h2lFieldJson?.crop_id !== cropID) {
            cropActions.getCropByIdWithTulibBeds(cropID).then(setH2lFieldJson)
        }
    }, [drawerView, h2lFieldJson])

    if (h2lFieldJson === null) return <></>
    return (
        <>
            <Box sx={{ position: 'relative' }}>
                <Drawer
                    sx={{
                        width: 240,
                        flexShrink: 0,
                        '& .MuiDrawer-paper': {
                            width: 240,
                            boxSizing: 'border-box',
                        },
                    }}
                    variant='persistent'
                    open={true}
                >
                    <Toolbar />
                    <Box sx={{ m: 2 }}>
                        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                            <Stack direction={'row'} alignItems={'center'}>
                                <Typography
                                    variant='h5'
                                    sx={{
                                        overflow: 'hidden',
                                        overflowWrap: 'anywhere',
                                    }}
                                >
                                    {h2lFieldJson.name}
                                </Typography>
                                <CropParseStatusIcon fieldTestPassed={h2lFieldJson.test_pass} />
                            </Stack>
                            <FieldMenu
                                fieldID={h2lFieldJson.field_id}
                                cropID={h2lFieldJson.crop_id}
                                fieldName={h2lFieldJson.name}
                                fieldLat={h2lFieldJson.center.coordinates[1]}
                                fieldLon={h2lFieldJson.center.coordinates[0]}
                            />
                        </Stack>
                    </Box>
                    <Divider variant='middle' />
                    <Button
                        startIcon={
                            drawerView === TASK_LIST ? (
                                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                    <SpaIcon style={{ color: 'black', width: '30px', height: '30px' }} />
                                    <Typography variant='body1' color={'black'}>
                                        Show task planner
                                    </Typography>
                                </Stack>
                            ) : (
                                <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                    <TaskIcon style={{ color: 'black', width: '30px', height: '30px' }} />
                                    <Typography variant='body1' color={'black'}>
                                        Show task list
                                    </Typography>
                                </Stack>
                            )
                        }
                        onClick={handleToggleTaskList}
                    />
                    {drawerView === TASK_LIST ? (
                        <Box sx={{ p: 1 }}>
                            <TaskList isNearestToggleVisible={false} />
                        </Box>
                    ) : (
                        h2lFieldJson.crop_id === cropID && <TaskPlannerPanel />
                    )}
                </Drawer>
            </Box>
        </>
    )
}
