import CameraLensImg from '../assets/images/focus_cameras/camera_lens.png'
import { Typography, Stack } from '@mui/material'

export const AdjustmentStep = ({ cameraNum }: { cameraNum: string }) => {
    return (
        <Stack>
            <Typography variant='h5'>Adjustment: Camera {cameraNum}</Typography>
            <Typography component={'span'} variant='body2' gutterBottom>
                1. Ensure lens is securely screwed on to camera housing
            </Typography>
            <Typography component={'span'} variant='body2' gutterBottom>
                2. Adjust the lens aperture to its lowest value (2.4 for Tamron lenses) and tighten the thumbscrew
            </Typography>
            <Typography component={'span'} variant='body2' gutterBottom>
                3. Loosen the focus ring thumbscrew of the camera lens
            </Typography>
            <Typography component={'span'} variant='body2'>
                4. While the script is running:
            </Typography>
            <Typography component={'span'} variant='body2' sx={{ pl: 4 }}>
                - Slowly turn focus ring fully to one direction, against the endstop
            </Typography>
            <Typography component={'span'} variant='body2' sx={{ pl: 4 }}>
                - Slowly turn focus ring fully to the other direction, against the other endstop
            </Typography>
            <Typography component={'span'} variant='body2' sx={{ pl: 4 }} gutterBottom>
                - This must be done slow enough so the full motion takes about 10 seconds
            </Typography>
            <Typography component={'span'} variant='body2' gutterBottom>
                5. While the script is still still running, slowly adjust the focus ring until the focus measurement
                reads 95 or better
            </Typography>
            <Typography component={'span'} variant='body2'>
                Notes:
            </Typography>
            <Typography component={'span'} variant='body2' sx={{ pl: 4 }}>
                - This setting is very sensitive
            </Typography>
            <Typography component={'span'} variant='body2' sx={{ pl: 4 }} gutterBottom>
                - Make sure your own arm or head is not in view of the camera!
            </Typography>
            <Typography component={'span'} variant='body2' gutterBottom>
                6. While the script is still running, tighten the thumbscrew of the focus ring
            </Typography>
            <Typography component={'span'} variant='body2' gutterBottom>
                7. Ensure the focus measurement is still above 95
            </Typography>
            <Typography component={'span'} variant='body2'>
                {'8. Fixate the lens by applying a large drop of Loctite SF 7400 "Varnistop" to: '}
            </Typography>
            <Typography component={'span'} variant='body2' sx={{ pl: 4 }}>
                - Both thumbscrews between lens & camera body
            </Typography>
            <Typography component={'span'} variant='body2' sx={{ pl: 4 }} gutterBottom>
                - Between aperture & focus ring between focus & lens
            </Typography>
            <Typography component={'span'} variant='body2' gutterBottom>
                9. Ensure the focus measurement is still above 95 after fixation with Varnistop
            </Typography>
            <Typography component={'span'} variant='body2' gutterBottom>
                10. Close this screen
            </Typography>
            <img
                src={CameraLensImg}
                alt='description'
                style={{ borderRadius: '4px', width: '100%', height: 'auto' }}
            />
        </Stack>
    )
}
