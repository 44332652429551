import { useState, MouseEvent } from 'react'
import { Box, Typography, IconButton, Popover, Button } from '@mui/material'
import { BoundaryIcon } from '../_components'
import { Field } from '../types'
import { useNavigate } from 'react-router-dom'

export const CropBoundaryStatusIcon = ({ field }: { field: Field }) => {
    const navigate = useNavigate()
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
    const open = Boolean(anchorEl)
    const id = open ? 'simple-popover' : undefined

    const handlePopoverClick = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        setAnchorEl(e.currentTarget)
    }

    const handlePopoverClose = (e: MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        setAnchorEl(null)
    }
    const getBoundaryBtnColor = (field: Field): string => {
        if (!field.crop_id) return 'gray'
        return field.boundary_parsed ? '#2e7d32' : '#ed6d28'
    }

    const handleDrawBoundaryRedirect = () => navigate(`/fields/${field.id}/crops/${field.crop_id}/edit/boundary-draw`)

    let msg = null
    if (!field.crop_id) {
        msg = 'Not parsed'
    } else if (field.boundary_parsed) {
        msg = (
            <Box sx={{ p: 2 }}>
                <Typography>🇬🇧 Field has a boundary</Typography>
                <Typography>🇳🇱 Het veld heeft een grens</Typography>
            </Box>
        )
    } else {
        msg = (
            <Box sx={{ p: 2 }}>
                <Typography sx={{ whiteSpace: 'pre-line' }}>🇬🇧 No boundary found</Typography>
                <Typography sx={{ whiteSpace: 'pre-line' }}>🇳🇱 Geen grens gevonden</Typography>
                <Button variant='text' onClick={handleDrawBoundaryRedirect}>
                    Click to draw boundary
                </Button>
            </Box>
        )
    }
    return (
        <>
            <IconButton onClick={handlePopoverClick} sx={{ cursor: 'pointer' }}>
                <BoundaryIcon backgroundColor={getBoundaryBtnColor(field)} pathColor='white' />
            </IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                {msg}
            </Popover>
        </>
    )
}
