import { Box, Stack } from '@mui/material'
import { FocusAdjustment } from './FocusAdjustment'
import { LocateCameraStep } from './LocateCameraStep'
import { AdjustmentStep } from './AdjustmentStep'
import { PreperationStep } from './PreperationStep'

import { ModalBox } from '../_components'

export const FocusModal = ({
    machineName,
    machineModel,
    selectedCamera,
    handleClose,
}: {
    machineName: string
    machineModel: string
    selectedCamera: string
    handleClose: () => void
}) => {
    return (
        <ModalBox
            title={`Focus ${selectedCamera?.charAt(0).toUpperCase() + selectedCamera?.slice(1)}`}
            open={true}
            handleDialogClose={handleClose}
        >
            <Box
                sx={{
                    position: 'sticky',
                    top: 0,
                    zIndex: 10,
                    backgroundColor: 'background.paper',
                    pb: 1,
                }}
            >
                <FocusAdjustment
                    machineName={machineName}
                    cameraNum={selectedCamera && selectedCamera.slice(-1)}
                    handleClose={handleClose}
                />
            </Box>
            <Stack
                spacing={1}
                sx={{
                    overflowY: 'auto',
                    maxHeight: '70vh', // Adjust to control height
                    scrollbarWidth: 'thin',
                    '&::-webkit-scrollbar': {
                        width: '0.4em',
                    },
                    '&::-webkit-scrollbar-track': {
                        background: '#888',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        backgroundColor: '#f1f1f1',
                    },
                    '&::-webkit-scrollbar-thumb:hover': {
                        background: '#555',
                    },
                    bgcolor: 'background.paper',
                    p: 2,
                }}
            >
                <PreperationStep />
                <LocateCameraStep model={machineModel} cameraNum={selectedCamera} />
                <AdjustmentStep cameraNum={selectedCamera} />
            </Stack>
        </ModalBox>
    )
}
