import React, { useState } from 'react'
import { AppBar, Toolbar, Box, Typography, IconButton, Dialog, DialogContent, Alert, Stack } from '@mui/material'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'
import CloseIcon from '@mui/icons-material/Close'
import { useTheme } from '@mui/material/styles'

const ExpandedHelpBox = ({ title, body, handleClose }: { title: string; body: any; handleClose: () => void }) => {
    const theme = useTheme()
    return (
        <Dialog open={true} onClose={handleClose}>
            <Stack direction={'row'} justifyContent={'space-between'}>
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar
                        sx={{
                            backgroundColor: theme.palette.info.main,
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        <Typography variant={'body1'} fontWeight='500' sx={{ whiteSpace: 'nowrap' }}>
                            {title}
                        </Typography>
                        <IconButton color='inherit' onClick={handleClose} aria-label='close'>
                            <CloseIcon />
                        </IconButton>
                    </Toolbar>
                </AppBar>
            </Stack>
            <DialogContent dividers sx={{ backgroundColor: theme.palette.info.main }}>
                {body}
            </DialogContent>
        </Dialog>
    )
}

export const MachineInstruction = ({
    instruction,
    helpboxBody,
    color,
}: {
    instruction: string
    helpboxBody: React.ReactElement
    color: string
}) => {
    const [showHelp, setShowHelp] = useState(false)
    const handleClose = () => setShowHelp(false)

    return (
        <Alert
            icon={false}
            sx={{
                bgcolor: color,
                borderRadius: '28px',
                padding: '20px',
                position: 'absolute',
                // border: '1px solid #374151',
                top: '6%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                height: '50px',
                display: 'flex',
                justifyContent: 'flex-start',
                alignItems: 'center',
                cursor: 'pointer',
                zIndex: 1000,
            }}
            onClick={() => setShowHelp(!showHelp)}
        >
            <Box display='flex' justifyContent='space-between' alignItems={'center'}>
                <Typography fontWeight='500' sx={{ color: '#fff', whiteSpace: 'nowrap' }} fontSize={'16px'}>
                    {instruction}
                </Typography>
                <IconButton size='small' sx={{ ml: 0.1, color: '#fff' }}>
                    <HelpOutlineIcon fontSize='medium' />
                </IconButton>
            </Box>
            {showHelp && <ExpandedHelpBox title={instruction} body={helpboxBody} handleClose={handleClose} />}
        </Alert>
    )
}
