import { atom, selector } from 'recoil'
import { MultiPoint, Task, TaskQuery } from '../types'

const taskQuery = atom<TaskQuery>({
    key: 'task_query',
    default: { data: null, isLoading: false },
})

const taskAtom = selector<Task | null>({
    key: 'task_atom',
    get: ({ get }) => {
        const task = get(taskQuery);

        // Return null if task is undefined or not fully loaded
        if (task?.data && task?.isLoading === false) {
            return task.data;
        } else {
            return null; // Explicitly return null when the condition is not met
        }
    },
});

const flatTaskPointsSelector = selector<[number, number][]>({
    key: 'flat_task_points',
    get: ({ get }): [number, number][] => {
        const task = get(taskAtom)
        const line: [number, number][] = []
        if (task) {
            task.json_path.features.forEach((feature: any) => {
                feature.geometry.coordinates.forEach((v: MultiPoint) => {
                    line.push([v[0], v[1]])
                })
            })
        }
        return line
    },
})

export { taskAtom, taskQuery, flatTaskPointsSelector }
