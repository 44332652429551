import { useEffect, useState } from 'react'
import { Dialog, Button, Grid, Stack, AppBar, Toolbar, Typography, DialogContent, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import CameraIcon from '@mui/icons-material/Camera'
import RefreshIcon from '@mui/icons-material/Refresh'
import { useRecoilValue } from 'recoil'
import { machineModelAtom, machineNameAtom } from '../_state'
import { useNavigate } from 'react-router-dom'
import { ws, socket } from '../_helpers'
import ImageLoader from './ImageLoader'
import { CameraCalibration } from './CameraCalibration'
import { FocusModal } from './FocusModal'

type CameraStatus = {
    status: string
    filepath?: string
    timestamp?: number
}

type CalibrationSocketMessage = Record<string, CameraStatus>

const CameraToolbar = ({ handleClose }: { handleClose: () => void }) => (
    <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
            <CameraIcon fontSize='small' />
            <Typography sx={{ ml: 2, flex: 1 }} variant='h6'>
                Cameras Setup
            </Typography>
            <IconButton color='inherit' onClick={handleClose} aria-label='close'>
                <CloseIcon />
            </IconButton>
        </Toolbar>
    </AppBar>
)

const CameraCard = ({ camera, status, onFocus }: { camera: string; status: CameraStatus; onFocus: () => void }) => (
    <Grid item xs={1} md={1} key={camera}>
        <Stack spacing={1} alignItems='center' width='100%'>
            <Typography variant='h4'>{camera.charAt(0).toUpperCase() + camera.slice(1).toLowerCase()}</Typography>
            <Stack sx={{ borderRadius: '8px', border: '2px solid #fff', width: '250px' }} alignItems={'center'}>
                <ImageLoader
                    filepath={status.filepath}
                    containerStyle={{ width: '244px', height: '250px', borderRadius: '8px' }}
                    enableFullscreen={true}
                    loading={status.status === 'loading'}
                />
                <Typography variant='body1'>
                    {status.timestamp
                        ? new Date(status.timestamp * 1000).toLocaleString().replace(',', '')
                        : 'Photo not found'}
                </Typography>
            </Stack>

            <Button sx={{ width: '250px' }} color='secondary' variant='contained' onClick={onFocus}>
                Focus
            </Button>
        </Stack>
    </Grid>
)

const CameraGrid = ({
    cameraStatus,
    handleFocus,
}: {
    cameraStatus: CalibrationSocketMessage
    handleFocus: (camera: string) => void
}) => (
    <Grid container columns={{ xs: 1, md: 4 }} rowSpacing={1}>
        {Object.entries(cameraStatus).map(([camera, status]) => (
            <CameraCard key={camera} camera={camera} status={status} onFocus={() => handleFocus(camera)} />
        ))}
    </Grid>
)

const CameraRefreshButton = ({
    cameraStatus,
    handleRefresh,
}: {
    cameraStatus: CalibrationSocketMessage
    handleRefresh: () => void
}) => (
    <IconButton
        color='secondary'
        sx={{
            animation: Object.values(cameraStatus).some((camera) => camera.status === 'loading')
                ? 'spin 1s linear infinite'
                : 'none',
        }}
        onClick={handleRefresh}
        disabled={Object.values(cameraStatus).some((camera) => camera.status === 'loading')}
    >
        <RefreshIcon fontSize='large' />
    </IconButton>
)

const CamerasSetup = () => {
    const navigate = useNavigate()
    const machineName = useRecoilValue(machineNameAtom)
    const machineModel = useRecoilValue(machineModelAtom)
    const [modalOpen, setModalOpen] = useState(false)
    const [selectedCamera, setSelectedCamera] = useState<string | null>(null)
    const [cameraStatus, setCameraStatus] = useState<CalibrationSocketMessage | null>(null)

    const handleClose = () => navigate(location.pathname.replace('/cameras-setup', ''))
    const handleRefresh = () => socket.emit('sync')
    const handleCameraStatus = (status: CalibrationSocketMessage) => setCameraStatus(status)

    const handleFocus = (camera: string) => {
        setModalOpen(true)
        setSelectedCamera(camera)
    }
    const handleModalClose = () => setModalOpen(false)

    useEffect(() => {
        ws('cis', `${machineName}/calibration`)
        if (socket) {
            socket.on('CAMERA_IMAGES_STATUS', handleCameraStatus)
        }
        return () => {
            if (socket) {
                socket.off(machineName)
                socket.close()
            }
        }
    }, [machineName])

    return (
        <Dialog fullScreen open onClose={handleClose}>
            <Stack direction='row' justifyContent='space-between'>
                <CameraToolbar handleClose={handleClose} />
            </Stack>
            <DialogContent>
                <Stack direction='row' alignItems='start' spacing={1} sx={{ mb: 2 }}>
                    {cameraStatus && <CameraGrid cameraStatus={cameraStatus} handleFocus={handleFocus} />}
                    {cameraStatus && <CameraRefreshButton cameraStatus={cameraStatus} handleRefresh={handleRefresh} />}
                </Stack>
                <CameraCalibration handleRefresh={handleRefresh} />
                {modalOpen && (
                    <FocusModal
                        machineName={machineName}
                        machineModel={machineModel}
                        selectedCamera={selectedCamera}
                        handleClose={handleModalClose}
                    />
                )}
            </DialogContent>
        </Dialog>
    )
}

export { CamerasSetup }
