import { useState } from 'react'
import { Button, Stack, Typography } from '@mui/material'
import { SystemCommandsManager, ModalBox, PdfView } from '../_components'

export const CameraCalibration = ({ handleRefresh }: { handleRefresh: () => void }) => {
    const [systemCommand, setSystemCommand] = useState<string | null>(null)
    const handleDialogClose = () => {
        if (systemCommand === 'calibrate_all_cameras') handleRefresh()
        setSystemCommand(null)
    }
    return (
        <>
            <Stack spacing={1}>
                <Typography variant='h4'>Camera Calibration</Typography>
                <Stack direction={{ sm: 'column', md: 'row' }} spacing={2} justifyContent={'space-around'}>
                    <Stack justifyContent={'space-evenly'} spacing={1}>
                        <Typography>Machine Origin Calibration</Typography>
                        <Button
                            color='secondary'
                            variant='contained'
                            onClick={() => setSystemCommand('calibrate_laser_on')}
                        >
                            1. Laser ON measurement
                        </Button>
                        <Button
                            color='secondary'
                            variant='contained'
                            onClick={() => setSystemCommand('calibrate_laser_off')}
                        >
                            2. Laser OFF measurement
                        </Button>
                    </Stack>
                    <Stack spacing={1}>
                        <Typography>Extristic Camera Calibration</Typography>
                        <Button
                            color='secondary'
                            variant='contained'
                            onClick={() => setSystemCommand('calibrate_all_cameras')}
                        >
                            3. Calibrate Cameras
                        </Button>
                    </Stack>
                </Stack>
                <Typography variant='h5'>Procedure</Typography>
                <PdfView pdf={`${process.env.REACT_APP_API_URL}/api/v1/utils/calibration.pdf`} />
            </Stack>
            {systemCommand && (
                <ModalBox title={'System Commands'} open={true} handleDialogClose={handleDialogClose}>
                    <SystemCommandsManager preSelectedCmd={systemCommand} />
                </ModalBox>
            )}
        </>
    )
}
