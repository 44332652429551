import { Box } from '@mui/material/'
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress'

export const CircularStatic = ({
    size,
    color = 'inherit',
    children,
}: {
    size: number
    color?: CircularProgressProps['color']
    children: JSX.Element
}) => {
    return (
        <>
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress color={color} size={size} />
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    {children}
                </Box>
            </Box>
        </>
    )
}
