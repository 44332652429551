import { io, Socket } from 'socket.io-client'
import UserService from '../services/UserService'

let socket: Socket

export const ws = (module: string, namespace: string) => {
    if (module === 'fss') {
        socket = io(`${process.env.REACT_APP_FSS_URL}/${namespace}`, {
            auth: {
                token: UserService.getToken(),
                client_type: 'ui-client',
            },
        })
    } else if (module === 'msu') {
        socket = io(`${process.env.REACT_APP_MSU_URL}/${namespace}`, {
            auth: {
                token: UserService.getToken(),
                client_type: 'ui-client',
            },
        })
    } else if (module === 'cis') {
        socket = io(`${process.env.REACT_APP_CIS_URL}/${namespace}`, {
            auth: {
                token: UserService.getToken(),
                client_type: 'ui-client',
            },
        })
    }

    return socket
}

export { socket }

export const disconnectSocket = () => {
    if (socket) {
        socket.disconnect()
        socket.close()
    }
}
